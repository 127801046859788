/** @format */

/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/default-props-match-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */

import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styled from "styled-components";

import IntlMessages from "../../../helpers/IntlMessages";

const NotificationStyle = styled.div`
    border-radius: 15px;
    color: #ffffff;
    background-color: #000000;
    .closeNotification {
        color: ${(props) => props.theme.THEME_COLOR_1} !important;
    }
`;

class Notification extends React.Component {
    static propTypes = {
        type: PropTypes.oneOf(["info", "success", "warning", "error", "primary", "secondary"]),
        title: PropTypes.node,
        message: PropTypes.node.isRequired,
        // eslint-disable-next-line react/no-unused-prop-types
        timeOut: PropTypes.number,
        onClick: PropTypes.func,
        onRequestHide: PropTypes.func,
        customClassName: PropTypes.string,
    };

    static defaultProps = {
        type: "info",
        title: null,
        message: null,
        timeOut: 5000,
        onClick: () => {},
        onRequestHide: () => {},
        customClassName: "",
    };

    componentDidMount = () => {
        // const { timeOut } = this.props;
        // if (timeOut !== 0) {
        //     this.timer = setTimeout(this.requestHide, timeOut);
        // }
    };

    componentWillUnmount = () => {
        if (this.timer) {
            clearTimeout(this.timer);
        }
    };

    handleClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
        this.requestHide();
    };

    requestHide = () => {
        const { onRequestHide } = this.props;
        if (onRequestHide) {
            onRequestHide();
        }
    };

    render() {
        const { type, message } = this.props;
        let { title } = this.props;
        const className = classnames(["notification", `notification-${type}`, this.props.customClassName]);
        title = title ? <h4 className="title">{title}</h4> : null;
        return (
            <NotificationStyle className={className} onClick={this.handleClick}>
                <div className="notification-message" role="alert">
                    {title}
                    <div className="d-flex  justify-content-between">
                        {/* {message} */}
                        {/* using dynamic id for language feature */}
                        <p className="message text-white font-weight-bold h6">
                            <IntlMessages id={message} />
                        </p>
                        <p className="message  font-weight-bold h5 closeNotification">x</p>
                    </div>
                </div>
            </NotificationStyle>
        );
    }
}

export default Notification;
