/**
 * /* eslint-disable class-methods-use-this
 *
 * @format
 */

/** @format */

// import { toJS } from "mobx";
// import UIState from "Store/States/UIState";
// import UserState from "Store/States/UserState";
// import API from "../utils/API";
// import axios from "axios";
import API from "../utils/API";
import LogService from "./LogService";
// const baseURL = "http://localhost:5000";

// eslint-disable-next-line class-methods-use-this
class ManageDataService {
    // eslint-disable-next-line
    async saveCSV(ids: any, fields: any, currentLanguage: string) {
        try {
            const response = await API.post(`/manageData/saveCSV`, { ids, fields, currentLanguage });
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> saveCSV -> error", error);
        }
    }

    // eslint-disable-next-line
    async savePDF(ids: any, fields: any, orgId: string, currentLanguage: string) {
        try {
            const response = await API.post(`/manageData/savePDF`, { ids, fields, orgId, currentLanguage });
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> savePDF -> error", error);
        }
    }

    // eslint-disable-next-line
    async downloadOriginalCV(ids: any) {
        try {
            const response = await API.post(`/manageData/downloadOriginalCV`, { ids });
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> savePDF -> error", error);
        }
    }

    // eslint-disable-next-line
    async search(name: any, email: any, address: any, work_experience: any, education: any, gender: any, age: number, offset: any, limit: any) {
        // * creating query string
        const query = [];
        if (name) query.push(`name=${name}`);
        if (email) query.push(`email_id=${email}`);
        if (address) query.push(`address=${address}`);
        if (work_experience) query.push(`work_experience=${work_experience}`);
        if (education) query.push(`education=${education}`);
        if (gender) query.push(`gender=${gender}`);
        if (age) query.push(`age=${age}`);
        query.push(`offset=${offset}`);
        query.push(`limit=${limit}`);

        const searchQuery = query.join("&");
        // console.log(searchQuery);
        try {
            const response = await API.get(`/manageData/search?${searchQuery}`);
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> saveCSV -> error", error);
        }
    }

    // eslint-disable-next-line
    async getBatchLogs(userId: string) {
        try {
            const response = await API.get(`/users/getBatchLogs?userId=${userId}`);
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> getBatch -> error", error);
        }
    }

    // eslint-disable-next-line
    async deletedBatchLogs(id: string) {
        try {
            const response = await API.post(`/users/deleteBatchLog`, { id });
            return response.data;
        } catch (error) {
            LogService.error("🚀 -> ManageDataServices -> deletedBatchLogs -> error", error);
        }
    }
}

export default new ManageDataService();

// name = undefined;

//     email = undefined;

//     location = undefined;

//     work_experience = undefined;

//     education = undefined;
