/** @format */
/* eslint-disable import/no-cycle */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-console */

// import API from "../utils/API";
import jwt_decode from "jwt-decode";
import { toJS } from "mobx";
import { NotificationManager } from "../components/common/react-notifications";
import UIState from "../mobx/states/UIState";
import UserState from "../mobx/states/UserState";
import API from "../utils/API";
import setAuthToken from "../utils/setAuthToken";
import LogService from "./LogService";

// const baseURL = "http://localhost:5000";

class UserService {
    // Get 1 user
    async getUser(id: any) {
        const response = await API.get(`/users/getUser/${id}`);

        return response.data;
    }

    // Password Update
    async resetPassword(id: any, currentPassword: string, newPassword: string, confirmPassword: string) {
        const response = await API.put(`/users/resetPassword/${id}`, {
            currentPassword,
            newPassword,
            confirmPassword,
        });
        return response.data;
    }

    // Settings Update
    async resetSettings(id: any, name: string, localization: string) {
        const response = await API.put(`/users/resetSettings/${id}`, {
            name,
            localization,
        });
        return response.data;
    }

    // SMTP updates
    // eslint-disable-next-line
    async addSMTPSettings(
        id: string,
        email: string,
        password: string,
        host: string,
        smtpAuth: string,
        secureType: string,
        port: string,
        signature: string
    ) {
        //

        try {
            const response = await API.put(`/users/emailSettings`, {
                // const response = await API.put(`/users/emailSettings`, {
                id,
                email,
                password,
                host,
                smtpAuth,
                secureType,
                port,
                signature,
            });
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // # Get Metadata with userid

    // eslint-disable-next-line
    async getMetadataWithUserId(userId: string, userType: string, status: string) {
        try {
            const response = await API.get(`/manageData/getMetadataWithUserId?userId=${userId}&userType=${userType}&status=${status}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // eslint-disable-next-line
    async getMetadataCountWithUserId(userId: string, userType: string, status: string) {
        try {
            const response = await API.get(`/manageData/getMetadataCountWithUserId?userId=${userId}&userType=${userType}&status=${status}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // get http://localhost:5000/manageData/getMetadata?pageNo=1&rowsPerPage=6

    // # Get Metadata
    // # Provide Parameter pageNo(Number, Required) and rowsPerPage(Number, Required)
    // # This returns the array of objects of metadata.
    // # Page Number Starts from 1
    // # If data is not available it returns an empty array

    // get candidates
    // eslint-disable-next-line
    async getMetaData(userId: string) {
        try {
            const response = await API.get(`/manageData/getMetadata?userId=${userId}`);
            console.log("response.data: ", response.data);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // eslint-disable-next-line
    async getMetaData_v2(userType: string, userId: string, offset: number, limit: number, flag = null, orderBy = 0, orderDir = 0, filter = []): Promise<any> {
        try {
            // eslint-disable-next-line
            const response = await API.get(`/manageData/v2/getMetadata/${offset}/${limit}/${userType}/${userId}/${flag}/${orderBy}/${orderDir}?filter=${JSON.stringify(filter)}`);
            // console.log(" v2 > response.data: ", response.data);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
        return "";
    }

    // # Get total count of metadata
    // # It returns count field i.e. total count of metadata in db
    // get http://localhost:5000/manageData/getMetadataCount
    // eslint-disable-next-line
    async getMetaDataCount(userType: string, userId: string, flag = null) {
        try {
            const response = await API.get(`/manageData/v2/metaDataCount/${userType}/${userId}/${flag}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    //  get all metadata data for admin user
    // eslint-disable-next-line
    async getAllAdminMetaData(userId: string) {
        try {
            const response = await API.get(`/manageData/getAllAdminMetaData?userId=${userId}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // get all metadata count
    // eslint-disable-next-line
    async getAllAdminMetaDataCount(userId: string) {
        try {
            const response = await API.get(`/manageData/allAdminMetaDataCount?userId=${userId}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // # Delete metadata
    // # Provide id parameter(String, required)
    // # It returns success field with success message
    // get http://localhost:5000/manageData/deleteMetaData?id=60ee97c8c1f61600613ab71a
    // eslint-disable-next-line
    async deleteMetaData(id: string) {
        try {
            const response = await API.get(`/manageData/deleteMetaData?id=${id}`);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // ###
    // #  Update Meta Data
    // # Provide id parameter(String, required) in body
    // # Provide updatedData parameter(Object) with fields to update in body
    // post http://localhost:5000/manageData/updateMetaData
    // content-type: application/json

    // {
    //     "id":"60ee9a03f8c52900a95092da",
    //     "updatedData":{
    //         "candidateName":"Name Changed",
    //         "status":"Completed"
    //     }
    // }
    // eslint-disable-next-line
    async updateMetaData(id: string, updatedData: any) {
        try {
            const response = await API.post(`/manageData/updateMetaData`, {
                id,
                updatedData,
            });
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
        }
    }

    // send single mail
    async sendEmail(from: "string", to: "string", messagebody: "string") {
        const response = await API.post(`/users/candidates/sendEmail`, { from, to, messagebody });
        return response.data;
    }

    // send multiple email
    async sendBulkEmail(from: any, to: "string", messagebody: "string") {
        const response = await API.post(`/users/candidates/sendBulkEmail`, { from, to, messagebody });
        return response.data;
    }

    /* eslint-disable @typescript-eslint/no-unused-vars */
    // eslint-disable-next-line class-methods-use-this
    public LoginUser() {
        // DEMO LOGIN USERPROCESS
        return new Promise((resolve, _reject) => {
            setTimeout(() => {
                resolve(true);
            }, 2000);
        });
    }

    async Login(email: string, password: string, userType: string) {
        const response = await API.post(`/users/login`, { email, password, userType });
        return response;
    }

    public checkAndRedirectLoggedInUser(usState, history) {
        // Set auth token header auth
        const token = localStorage.jwtToken;
        setAuthToken(token);
        // Decode token and get user info and exp
        const decoded: any = jwt_decode(token);
        // Set user and isAuthenticated
        const {
            phoneNo,
            email,
            userType,
            roles,
            rolesForUser,
            id,
            isAuthenticated,
            name,
            orgName,
            orgLicenceFlag,
            orgLicenceDate,
            orgId,
            localization,
            adminId,
            smtpConfigurations,
            userImage,
        } = decoded;

        usState.setCurrentUserState({
            phoneNo,
            email,
            userType,
            roles,
            id,
            name,
            orgName,
            orgId,
            localization,
            isAuthenticated: true,
            adminId,
            rolesForUser,
            orgLicenceFlag,
            orgLicenceDate,
            smtpConfigurations,
            userImage,
        });
        // REDIRECTING USER TO RESPECTIVE PATH IF USER DATA PRESENT IN LOCAL STORAGE
        history.push(`/${userType}`);

        //
        //
        if (orgLicenceFlag === false) {
            const currentTime2 = new Date().getTime();
            //
            const expiryTime = new Date(orgLicenceDate).getTime();
            //
            if (expiryTime <= currentTime2) {
                //
                NotificationManager.error("Your licence has expired, please contact to administrator", "Error...!!", 5000, null, "filled");

                // Remove token from local storage
                localStorage.removeItem("jwtToken");
                // Remove auth header for future requests
                setAuthToken(false);
                usState.setCurrentUserState({ isAuthenticated: false });
            }
        }
        // Check for expired token
        const currentTime = new Date().getTime() / 1000; // to get in milliseconds

        // FUNCTION FOR LOGOUT AFTER EXPIRY
        if (decoded.exp < currentTime) {
            // Logout user
            // Remove token from local storage
            localStorage.removeItem("jwtToken");
            // Remove auth header for future requests
            setAuthToken(false);
            usState.setCurrentUserState({ isAuthenticated: false });
            history.push("/");
        }
    }

    public async clearBatchInputs({ templateId, userId }): Promise<any> {
        try {
            // preparing form data
            const reqData = { templateId, userId };
            const response = await API.post("/upload/clearBatchInputs", reqData);

            return response.data;
        } catch (error) {
            LogService.error(error.response);
            return error;
        }
    }

    public async saveToServer({ userId, orgId, currentFileSelected, uploadType }): Promise<any> {
        try {
            console.log("currentFileSelected: ", currentFileSelected);
            // preparing form data
            const formData = new FormData();
            formData.append("uploadType", uploadType);
            formData.append("pageCount", "0");
            formData.append("userId", userId);
            formData.append("orgId", orgId);
            formData.append("status", "processed");
            formData.append("image", currentFileSelected);
            const response = await API.post("/upload/saveFile", formData);

            return response.data;
        } catch (error) {
            LogService.error(error.response);
            return error;
        }
    }

    async processImage(reqData: {
        processType: any;
        action: any;
        templateId?: any;
        totalPages: number;
        templateName?: string;
        isAutomatic?: boolean;
        isActive?: boolean;
        tempType?: string;
        isSemiAutomatic?: boolean;
        isBatchManual?: boolean;
        socketId: string;
        userId: string;
        orgId: string;
        imagePath?: string;
        singlePageInvoiceClasses?: any[];
        folderPath?: any;
        multiPageInvoiceClasses?: any;
    }) {
        try {
            //

            const response = await API.post("/extractDataProcess", { ...reqData });

            return response.data;
        } catch (error) {
            return LogService.error(error.response);
        }
    }

    // remove image or pdf folder
    async removeImage({ isFolder, filePath }: { isFolder: boolean; filePath: string }) {
        try {
            const response = await API.post("/upload/removeImage", {
                isFolder,
                filePath,
            });

            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
            return error.response.data;
        }
    }

    // trigger yolo process
    async triggerAutoCoordinateProcess(reqData: { filePath: string; socketId: string; isFolder: boolean; templateType: string }) {
        try {
            const response = await API.post("/processYolo", { ...reqData });

            LogService.info(response.data);
            return response.data;
        } catch (error) {
            LogService.error(error.response.data);
            return error.response.data;
        }
    }

    // FETCH TEMPLATES
    async fetchTemplateList(userId: string) {
        const response = await API.post("/manageTemplate/fetchTemplateList", {
            userId,
        });
        return [...response.data];
    }

    // DEELTE TEMPLATE
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteTemplateById(tempId: string, userId: string): Promise<any> {
        const response = await API.post("/manageTemplate/deleteTemplate", { tempId });
        // await this.fetchTemplateList(userId);
        return response;
    }

    // add processed data
    async saveProcessedData(reqData: { orgId: string; adminId: string; userId: string; docPath: any; processedData: any }) {
        try {
            // console.log(reqData);

            //
            const response = await API.post("/manageData/saveProcessedData", { ...reqData });
            LogService.info(response.data);
            return response.data;
        } catch (error) {
            LogService.error(error.response?.data);
            return error.response?.data;
        }
    }

    // eslint-disable-next-line consistent-return
    refactorYoloCordinates(yoloCoordinates: any): any {
        const mappedTableClasses = {
            quantity: "QUANTITY",
            serial_numbers: "SERIAL_NO",
            unit_price: "PRICE",
            amount: "AMOUNT",
            descriptions: "DESCRIPTION",
        };
        // FOR SINGLE PAGE ARRAY
        if (Array.isArray(yoloCoordinates)) {
            yoloCoordinates.map((obj) => {
                if (mappedTableClasses[obj.comment]) {
                    // eslint-disable-next-line no-param-reassign
                    obj.comment = mappedTableClasses[obj.comment];
                }
                return obj;
            });
            return yoloCoordinates;
        }

        // ON MULTI PAGE OBJECT
        if (!Array.isArray(yoloCoordinates)) {
            Object.keys(yoloCoordinates).forEach((pageName) => {
                yoloCoordinates[pageName].map((obj) => {
                    if (mappedTableClasses[obj.comment]) {
                        // eslint-disable-next-line no-param-reassign
                        obj.comment = mappedTableClasses[obj.comment];
                    }
                    return obj;
                });
            });
            return yoloCoordinates;
        }
    }

    /**
     * TO EXTRACT DATA FROM SINGLE PAGE FOOTER INVOICE
     */
    public onSingleFooterData(userState: UserState, uiState: UIState, response: { templateId: any; tableCoordinates: any }) {
        const { templateId, tableCoordinates } = response;
        const templatesList = toJS(userState.templateList);
        // eslint-disable-next-line no-underscore-dangle
        const selectedTemplate = templatesList.filter((prop: { _id: any }) => prop._id === templateId)[0];

        // FILTERING FIXED HEADER FOOTER COORDINATES
        const filteredCoordinates = selectedTemplate.singlePageInvoiceClasses.filter(
            (obj) => !userState.fixedHeaderFooterClasses.includes(obj.comment) && obj.id && obj.comment !== "FOOTER_TEXT"
        );

        // // FOR SINGLE PAGE DATA COMING AS ARRAY
        // MERGING WITH NEW COORDINATES
        // eslint-disable-next-line no-param-reassign
        uiState.annotatePages[uiState.currentSelectedPage] = [...filteredCoordinates, ...tableCoordinates];
    }

    public onMultiFooterData(userState: UserState, uiState: UIState, response: { templateId: any; tableCoordinates: any }) {
        LogService.info("multipage footer response 👀 ", response);
        const { templateId, tableCoordinates } = response;
        const templatesList = toJS(userState.templateList);
        // eslint-disable-next-line no-underscore-dangle
        const selectedTemplate = templatesList.find((prop: { _id: any }) => prop._id === templateId);

        // const templatePagesCount = selectedTemplate.totalPages;
        // const currentPagesCount = Number(uiState.lastPageCount);

        const templateLastPageName = selectedTemplate.multiPageInvoiceClasses.LAST_PAGE_NAME;
        // TOTAL PAGE NUMBER IS LAST PAGE NUMBER
        // const lastPageNumber = uiState.totalPdfPages;
        // const currentLastPageName = `page-${lastPageNumber}`;

        const CONSTANTS = ["FOOTER_TEXT", "LAST_PAGE_NAME", templateLastPageName];

        const finalCoordinates = {};
        Object.keys(selectedTemplate.multiPageInvoiceClasses).map((pageName) => {
            // if (Array.isArray(selectedTemplate["multiPageInvoiceClasses"][pageName])) {
            //     finalCoordinates[pageName] = selectedTemplate["multiPageInvoiceClasses"][pageName].filter(
            //         (obj) =>
            //             !UserState.fixedHeaderFooterClasses.includes(obj["comment"]) && obj["comment"] !== "FOOTER_TEXT"
            //     );
            // }
            // CONSIDER ONLY ACTUAL PAGE NAMES
            if (CONSTANTS.includes(pageName) !== true) {
                // finalCoordinates[pageName] = [...finalCoordinates[pageName]];
                finalCoordinates[pageName] = [...selectedTemplate.multiPageInvoiceClasses[pageName]];
            }
            return "";
        });

        /**
         * if template page count is 2 and current invoice count is more than 3 then
         * apply first page coordinates to middle pages except last page
         */

        // if (templatePagesCount === 2 && currentPagesCount >= 3) {
        //     // eslint-disable-next-line no-plusplus
        //     for (let index = templatePagesCount; index < currentPagesCount; index++) {
        //         finalCoordinates[`page-${index}`] = [...selectedTemplate.multiPageInvoiceClasses["page-1"]];
        //     }
        // }

        /**
         * if template page count is 3 and current invoice is greater or equal than 3
         * then user must have defined 2nd page coordinates
         * 2nd page coordinates will use for all middle pages except last page
         */
        // if (templatePagesCount === 3 && currentPagesCount >= 3) {
        //     // eslint-disable-next-line no-plusplus
        //     for (let index = 3; index < currentPagesCount; index++) {
        //         finalCoordinates[`page-${index}`] = [...selectedTemplate.multiPageInvoiceClasses["page-2"]];
        //     }
        // }

        // if (currentPagesCount >= 3) {
        //     // eslint-disable-next-line no-plusplus
        //     for (let index = 3; index < currentPagesCount; index++) {
        //         finalCoordinates[`page-${index}`] = [...selectedTemplate.multiPageInvoiceClasses["page-2"]];
        //     }
        // }

        // finalCoordinates[currentLastPageName] = [...tableCoordinates];

        //
        // eslint-disable-next-line no-param-reassign
        uiState.annotatePages = finalCoordinates;
    }

    async fetchClassessList() {
        const response = await API.get("/manageClasses/fetch");
        return response.data;
    }

    /**
     * TO GET DYNAMIC FOOTER COORDINATE DATA FOR MULTI PAGE
     */
    async getMultiPageFooterCoordinates(reqData: {
        folderPath: string;
        currentLastPageName: string;
        templateLastPageName: string;
        headerCoordinates: any;
        socketId: string;
        templateType: string;
        templateId: string;
    }) {
        try {
            const response = await API.post("/getFooterCoordinates", { ...reqData });
            return response.data;
        } catch (error) {
            LogService.error(error);
        }
        return "";
    }

    async processYolo(reqData: { userId: string; toProcessPath: string; socketId: string; isFolder: boolean }) {
        //
        const response = await API.post("/processYolo", { ...reqData });
        //
        return response.data;
    }

    // REMOVE IMAGE OR PDF FOLDER
    async clearImage({ isFolder, removePath }: { isFolder: boolean; removePath: string }) {
        const response = await API.post("/upload/removeImage", {
            isFolder,
            removePath,
        });

        return response.data;
    }

    /**
     * TO GET DYNAMIC FOOTER COORDINATE DATA FOR SINGLE PAGE
     */
    async getSinglePageFooterCoordinates(reqData: {
        imagePath: any;
        headerCoordinates: any;
        socketId: string;
        templateType: string;
        templateId: any;
    }) {
        //
        try {
            const response = await API.post("/getFooterCoordinates", { ...reqData });
            return response.data;
        } catch (error) {
            //
        }
        return "";
    }

    // CREATE TEMPLATE
    async createTemplate(reqData: any) {
        const newReqData = toJS(reqData);

        try {
            const response = await API.post("/manageTemplate/createTemplate", {
                ...newReqData,
            });
            return [response.data];
        } catch (error) {
            LogService.error(error);
        }
        return "";
    }

    // UPDATE TEMPLATE
    async updateTemplate(reqData: any) {
        const newReqData = toJS(reqData);
        try {
            await API.post("/manageTemplate/updateTemplate", {
                ...newReqData,
            });
            // return [...response.data];
        } catch (error) {
            LogService.error(error);
        }
    }

    // uploading profile photo
    async uploadProfilePic(userId: string, file) {
        const formData = new FormData();
        formData.append("userId", userId);
        formData.append("profilePic", file);
        try {
            const response = await API.put("/upload/uploadProfilePic", formData);
            return response.data;
        } catch (error) {
            LogService.error(error);
            return error.response.data;
        }
    }

    // Password Update
    async resetPasswordLoginEmail(email: string, loginUserType: string) {
        const response = await API.put(`/users/resetPasswordLoginEmail`, {
            email,
            loginUserType,
        });
        return response.data;
    }

    // Password Update
    async resetPasswordLogin(userId: string, password: string, cpassword: string, userType: string) {
        const response = await API.put(`/users/resetPasswordLogin`, {
            userId,
            password,
            cpassword,
            userType,
        });
        return response.data;
    }

    // UPDATE Token
    async updateUserDetails(email: string, userType: string) {
        try {
            localStorage.removeItem("jwtToken");
            const response = await API.post("/users/updateUserDetails", {
                email,
                userType,
            });
            const { errorMsg, success, token } = response.data;
            if (success) {
                localStorage.setItem("jwtToken", token);
                API.defaults.headers.common.Authorization = token;
            }
        } catch (error) {
            LogService.error(error);
        }
        return "";
    }
}

export default new UserService();
