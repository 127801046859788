/** @format */

// import { makeAutoObservable, autorun } from "mobx";
import DemoState from "./DemoState";
import UIState from "./UIState";
import UserState from "./UserState";
// import UIState from "./UIState";
// import UserState from "./UserState";

// ADD ENTRY OF YOUR STATE HERE
class RootState {
    DemoState: DemoState;

    UserState: UserState;

    UIState: UIState;

    constructor() {
        // makeAutoObservable(this);
        // this.UIState = new UIState();
        this.DemoState = new DemoState();

        this.UserState = new UserState();
        this.UIState = new UIState();
    }
}

export default RootState;
