/** @format */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */

// import { History } from "history";
import { makeAutoObservable, runInAction } from "mobx";
import { CLASS_LIST } from "../../constants";
// eslint-disable-next-line import/no-cycle
import UserService from "../../services/UserService";
import setAuthToken from "../../utils/setAuthToken";
import { baseURL } from "../../utils/API";
import profileImg from "../../assets/img/defaultUser.png";

type SmtpConfigurations = {
    email: string;
    password: string;
    host: string;
    smtpAuth: string;
    secureType: string;
    port: string;
    signature: string;
};

// "smtpConfigurations": {
//     "email": "mohit.shahu@vinnovatetechnologies.com",
//     "password": "mohitS@123",
//     "host": "smtp.hostinger.com",
//     "smtpAuth": true,
//     "secureType": "SSL",
//     "port": "465",
//     "signature": "this test singnatuer from mohit"
//   }
export default class UserState {
    templateList: Array<any> = [];

    classesList: Array<any> = [];

    userData = {
        roles: [],
        rolesForUser: [],
        userId: "",
        email: "",
        name: "",
        userType: "",
        phoneNo: 0,
        isAuthenticated: false,
        orgLicenceDate: "",
        orgLicenceFlag: "",
        orgName: "",
        orgId: "",
        localization: "",
        adminId: "",
        metaDataId: undefined,
        smtpConfigurations: {} as SmtpConfigurations,
        userImage: "",
    };

    // isUserLoggedIn = false;

    // userType: string;

    // classList = [
    //     "name",
    //     "address",
    //     "phone",
    //     "email",
    //     "sex",
    //     "dob",
    //     "date_of_birth",
    //     "personal_details",
    //     "nationality",
    //     "gender",
    //     "work_experience",
    //     "driving license",
    //     "languages",
    //     "education",
    // ];

    classList = [...CLASS_LIST];

    constructor() {
        makeAutoObservable(this);
    }

    fixedHeaderFooterClasses = [
        // "HEADER",
        // "footer",
        // "SERIAL_NO",
        // "DESCRIPTION",
        // "QUANTITY",
        // "PRICE",
        // "AMOUNT",
        // "UNIT_PRICE",
        // "PRICE",
        // "ITEM_NO",
    ];

    // updateUserState(userType) {
    //     //    REVIEW:storing the login state in localstorage and check every time
    //     this.isUserLoggedIn = true;
    //     this.userType = userType;
    // }
    // public init() {
    //     const userData = JSON.parse(localStorage.getItem("cvman_userData"));
    //
    //     this.userData = { ...userData };
    // }

    async setCurrentUserState(payload) {
        const {
            phoneNo,
            email,
            userType,
            name,
            roles,
            rolesForUser,
            id,
            orgName,
            orgLicenceFlag,
            orgLicenceDate,
            orgId,
            localization,
            isAuthenticated,
            adminId,
            smtpConfigurations,
            userImage,
        } = payload;

        // SETTING CURRENT USER GLOBAL STATE
        // this.userData.isAuthenticated = true;
        this.userData.isAuthenticated = isAuthenticated;
        this.userData.phoneNo = phoneNo;
        this.userData.roles = roles;
        this.userData.rolesForUser = rolesForUser;
        this.userData.name = name;
        this.userData.userType = userType;
        this.userData.email = email;
        this.userData.userId = id;
        this.userData.orgLicenceDate = orgLicenceDate;
        this.userData.orgLicenceFlag = orgLicenceFlag;
        this.userData.orgName = orgName;
        this.userData.orgId = orgId;
        this.userData.localization = localization;
        this.userData.adminId = adminId;
        this.userData.smtpConfigurations = smtpConfigurations;

        // set default user image
        let img;
        if (userImage === "") {
            img = profileImg;
        } else {
            img = `${baseURL}/${userImage}`;
        }
        this.userData.userImage = img;

        /* SETTING CURRENT USER LOCALIZATION */
        if (localization === "italian") {
            localStorage.setItem("currentLanguage", "it");
        } else if (localization === "english") {
            localStorage.setItem("currentLanguage", "en");
        }

        // set graph status
        this.setUserGraphApiCallStatus = "false";
        this.setAdminGraphApiCallStatus = "false";
        this.setSuperAdminGraphApiCallStatus = "false";
    }

    // getUserData() {
    //     return this.userData;
    // }

    onLogout() {
        // Remove token from local storage
        localStorage.removeItem("jwtToken");
        // Remove auth header for future requests
        setAuthToken(false);

        this.userData.isAuthenticated = false;
        this.userData.phoneNo = 0;
        this.userData.roles = [];
        this.userData.rolesForUser = [];
        this.userData.name = "";
        this.userData.userType = "";
        this.userData.email = "";
        this.userData.userId = "";
        this.userData.orgLicenceDate = "";
        this.userData.orgLicenceFlag = "";
        this.userData.orgName = "";
        this.userData.orgId = "";
        this.userData.localization = "";
        this.userData.adminId = "";
        this.userData.smtpConfigurations = {} as SmtpConfigurations;
        this.userData.userImage = "";
    }

    updateCurrentMetaDataId(metaDataId) {
        this.userData.metaDataId = metaDataId;
    }

    setTemplateList = (list) => {
        this.templateList = list;
    };

    async loadTemplateList() {
        runInAction(async () => {
            //
            const fetchedTemplateList = await UserService.fetchTemplateList(this.userData.userId);
            //

            this.setTemplateList(fetchedTemplateList);
        });
    }

    async loadClassesList() {
        const fetchClassesList = await UserService.fetchClassessList();
        //
        this.classesList = fetchClassesList;
    }

    // dashboard user stats data
    userMetaCount = [];

    userMetaCountPro = [];

    userMetaCountPen = [];

    adminMetaCount = [];

    adminMetaCountPro = [];

    adminMetaCountPen = [];

    superAdminMetaCount = [];

    superAdminMetaCountPro = [];

    superAdminMetaCountPen = [];

    // set graph status

    setUserGraphApiCallStatus = "";

    setAdminGraphApiCallStatus = "";

    setSuperAdminGraphApiCallStatus = "";
}
